import remcalc from "remcalc"

export const space = {
  0: 0,
  1: remcalc(4),
  2: remcalc(8),
  3: remcalc(16),
  4: remcalc(24),
  5: remcalc(32),
  6: remcalc(64),
  7: remcalc(128),
}

const fontSizes = [12, 14, 16, 24, 32, 48, 64, 96, 128]

export const breakpoints = [0, 580, 768, 1012, 1280]
breakpoints.xs = breakpoints[0]
breakpoints.s = breakpoints[1]
breakpoints.m = breakpoints[2]
breakpoints.l = breakpoints[3]
breakpoints.xl = breakpoints[4]

const elementSizes = {
  tappableArea: 48,
}

export const zIndexes = {
  base: 0,
  header: 999,
  footer: 999,
  modal: 10000,
}

const maxWidth = 1280

const colorVariables = {
  darkGrey: "#24302e",
  lightGrey: "#828287",
  white: "#D8D8D8",
  green: "#a2c5a7",
}

export default {
  breakpoints,
  space,
  elementSizes,
  fontSizes,
  zIndexes,
  maxWidth,
  durations: {
    fast: 180,
    normal: 250,
    long: 450,
    xlong: 750,
    xxlong: 1050,
  },
  easings: {
    in: {
      name: "inCirc",
      value: "cubic-bezier(0.6, 0.04, 0.98, 0.335)",
    },
    out: {
      name: "outCubic",
      value: "cubic-bezier(0.215, 0.61, 0.355, 1)",
    },
    inOut: {
      name: "inOutCirc",
      value: "cubic-bezier(1, 0, 0, 1)",
    },
  },
  colors: {
    bg: colorVariables.darkGrey,
    lightBg: colorVariables.green,
    faded: colorVariables.lightGrey,
    text: colorVariables.darkGrey,
  },
}
